import { Gisement } from 'tracabilite/models/dto/gisement';
import { SiteDocument } from 'global/models/dto/site-document';
import { ReceptionPrevueDAO } from 'appro/models/dao/reception-prevue';

export class ReceptionPrevue extends SiteDocument {

  public dateReception : any;
	public gisement : Gisement;
  public poidsNet : number;
  public nbreColis: number;
	public numReception : string;
  public commentaire : string;

  constructor(){
    super();
    this.dataType = "receptionPrevue";
    this.dateReception = new Date();
    this.dateReception.setHours(12,0,0);
  }

  public toDao(): ReceptionPrevueDAO{
		let reception: ReceptionPrevueDAO = new ReceptionPrevueDAO();
		Object.keys(this)
			.forEach(key => {

				if(key != 'site') reception[key] = this[key];
			});
		let site: any = this['site'];
		if(site instanceof Object) reception.site = site.id || site._id;
		else reception.site = site;
		reception.poidsNet =  parseInt(reception.poidsNet as any);
	  	reception.dateReception = new Date(this.dateReception);
	  	reception.dateReception = reception.dateReception.getTime();
	    if(reception.gisement.stockIntrant) delete reception.gisement.stockIntrant.site;
	    if(reception.gisement.ouvrageIncorporation) delete reception.gisement.ouvrageIncorporation.site;
	    if(reception.gisement.digesteur) delete reception.gisement.digesteur.site;
		return reception;
	}
}
