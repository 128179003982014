import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'global/services/login/login.service';
import { DatabaseService } from 'global/services/database/database.service';
import {BvTranslateService} from "global/services/bv-translate/bv-translate.service";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private _showMessage = false;
  private _username: string;
  private _password: string;
  private _loading = false;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private databaseService: DatabaseService,
    private bvTranslateService: BvTranslateService,
  ) { 
    
  }

  ngOnInit() {

  }

  login() {
    this._loading = true;
    this.loginService.login(this._username, this._password)
      .subscribe(
        jwt => {
          this.loginService.session()
            .subscribe(res => {
              if (res !== undefined && res.dbToken !== undefined) {
                if (res.data.organisation !== undefined && res.data.dataType === 'utilisateurOrganisation') {
                  this.databaseService.changeDatabase(res.data.organisation.database, res.data.email, res.dbToken);
                  this.databaseService.db.info()
                    .then(result => {
                      sessionStorage.removeItem('perimetre');
                      sessionStorage.removeItem('tmpBreadcrumb');
                      this._loading = false;
                      const lastRoute = sessionStorage.getItem('lastRoute');
                      if (lastRoute) {
                        sessionStorage.removeItem('lastRoute');
                        this.router.navigate([lastRoute]);
                      } else { this.router.navigate(['home-board']); }
                    }).catch(function (error) {
                      console.error(error);
                    });
                } else if (res.data.dataType === 'utilisateur') {
                  this._loading = false;
                  const lastRoute = sessionStorage.getItem('lastRoute');
                  if (lastRoute) {
                    sessionStorage.removeItem('lastRoute');
                    this.router.navigate([lastRoute]);
                  } else { this.router.navigate(['orga-board']); }
                }
              } else {
                this._loading = false;
                this._showMessage = true;
              }
            });
        },
        error => {
          this._loading = false;
          console.log(error);
        });
  }

}
