import { BiogasviewDocument } from './../biogasview-document';
import { Organisation } from './organisation';
import { Adresse } from './../adresse';
import { Entreprise } from './../entreprise';
import { PorteurProjet } from './../porteur-projet';
import { IBGVTreeNode } from 'global/interfaces/IBGVTreeNode';
import * as _ from 'lodash';

export class Site extends BiogasviewDocument implements IBGVTreeNode{
	//actually in database id exsits instead of _id
	id : string;
	nom: string;
  codeSite: string;
  codeAvoir: string;
  fuseauHoraire: string;
  dateCreation: any;
  dateRaccordement: any;
  dateMonitoring: any;
  typeOperation: any;
  schemaRed2: string;
  installateur: Entreprise = new Entreprise();
  proprietaire: Entreprise = new Entreprise();
  fournisseurValorisation: Entreprise = new Entreprise();
  negociant: Entreprise = new Entreprise();
  porteurProjet: PorteurProjet = new PorteurProjet();
  adresse: Adresse = new Adresse();
  organisation: Organisation = new Organisation();

  toNodes(parentId?: number, parentName?: any): any{
    let keyNames ={
      'nom': 'Nom du site'
    }

    return Object.keys(keyNames)
      .map((key,index) =>{
        if(parentId != undefined && parentName)
          return {
            id: index+parentId+1,
            name: keyNames[key],
            dataKey: parentName+'.'+key,
            parent: parentId
          }
        else return {
          id: index,
          name: keyNames[key],
          dataKey: key
        }
      })
  }
}
