import {
  Component,
  OnInit,
  Input,
  AfterContentChecked,
  ChangeDetectorRef,
  OnDestroy,
  DoCheck,
  AfterViewInit
} from '@angular/core';
import { Router, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import { LoginService } from 'global/services/login/login.service';
import { ConfirmationGuardService } from 'global/services/confirmation-guard/confirmation-guard.service';
import { PerimetreService } from 'global/services/perimetre/perimetre.service';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { NavigationService } from 'global/services/navigation/navigation.service';
import { ParametresService } from 'global/services/parametres/parametres.service';
import { DatabaseService } from 'global/services/database/database.service';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {

  @Input() bloc: any = {};
  @Input() subNavs: any[] = [];
  @Input() supportComponent = false;
  @Input() menu = false;
  user: any;
  private login = false;
  private _previousUrl: string;
  private _currentUrl: string;
  private sites = [];
  private organisation = null;
  private listeOrganisation = null;
  private perimetre = null;
  private perimetreString = '';
  private visibleSubNavs = [];
  private hiddenSubNavs = [];
  private perimetreDisabled = false;
  private perimetreSubscription;
  private activeItems: boolean[];
  private activeItemsSubscription;
  private breadcrumbSubscription;
  private parametres;
  private externUser = false;
  private currentLang;
  guideUtilisation: any;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _location: Location,
    private _confirmationGuard: ConfirmationGuardService,
    private _loginService: LoginService,
    private _perimetreService: PerimetreService,
    private _breadcrumbService: BreadcrumbService,
    private _navigationService: NavigationService,
    private _parametresService: ParametresService,
    private ref: ChangeDetectorRef,
    private _dbService: DatabaseService,
    private translate: TranslateService,
  ) {
    this._perimetreService.perimetre$.subscribe(perimetre => {
      if (perimetre) {
        this.perimetre = perimetre;
        this.perimetreString = perimetre.nom;
      }
    });

    this.translate.onLangChange.subscribe(lang => {
      this.guideUtilisation = this.translate.instant('app.global.termes.guideUtilisation');
    });
    this.currentLang = this.translate.currentLang;

  }

  ngOnInit() {
    this._route.queryParams.subscribe(params => {
      if (params.origin && params.origin === 'login') {
        this.login = true;
      }
    });
    this._router.events
    .pipe(
      filter(event => event instanceof RoutesRecognized),
      pairwise()
    )
    .subscribe((e: any) => {
      this._currentUrl = e[1].urlAfterRedirects;
      this._navigationService.setCurrentUrl(e[1].urlAfterRedirects);
      if (this._currentUrl.includes('/support/tickets/')) {
        if (this._currentUrl === '/support/tickets/list' && !e[0].urlAfterRedirects.includes('/support/tickets/')) {
          return this._navigationService.setPreviousUrl(e[0].urlAfterRedirects);
        }
      } else {
        return this._navigationService.setPreviousUrl(e[0].urlAfterRedirects);
      }
    });
    this.guideUtilisation = this.translate.instant('app.global.termes.guideUtilisation');
    this._previousUrl = this._navigationService.getPreviousUrl();
    this._currentUrl = this._navigationService.getCurrentUrl();
    this.parametres = this._parametresService.getParametres();
    this.sites = JSON.parse(sessionStorage.getItem('sites'));
    this.sites = this.sites.sort((a, b) => a.nom.localeCompare(b.nom));
    this.organisation = JSON.parse(sessionStorage.getItem('organisation'));
    this.listeOrganisation = JSON.parse(sessionStorage.getItem('listeOrganisation'));
    this.user = JSON.parse(sessionStorage.getItem('loggedUser'));
    if (this.user.dataType === 'utilisateur' && this._router.url === '/home-board') {
      this.externUser = true;
    } else {
      this.externUser = false;
    }
    if (!sessionStorage.getItem('perimetre')) {
      if (this.sites.length === 1) {
        this.changePerimetre(this.sites[0]);
      } else if (this.user.accessOrga) {
        this.changePerimetre(this.organisation);
      } else {
        this.changePerimetre(this.sites[0]);
      }
      sessionStorage.setItem('perimetre', JSON.stringify(this.perimetre));
    } else {
      this.changePerimetre(JSON.parse(sessionStorage.getItem('perimetre')));
    }
    this.visibleSubNavs = [...this.subNavs];
    this.hiddenSubNavs = [];
    this.handleNavOverflow();
    this.perimetreSubscription = this._perimetreService.perimetreDisabled$.subscribe(disabled => {
      this.perimetreDisabled = disabled;
    });
    this._navigationService.subNavs = this.subNavs;
    this.activeItemsSubscription = this._navigationService.activeItems$.subscribe(activeItems => {
      this.activeItems = activeItems;
    });
    this.breadcrumbSubscription = this._breadcrumbService.breadcrumb.subscribe( _breadcrumb => {
      const routes = this.parametres.routes.filter(route => this.subNavs.map((module: any) => module.id).includes(route.id));
      const subNavsRoutes = this.subNavs.map((module: any) => _.find(routes, ['id', module.id]));
      const breadcrumbRoutes = _breadcrumb.map(breadcrumbItem => breadcrumbItem.route);
      let activeItemIndex = -1;
      for (let i = 0; i < breadcrumbRoutes.length; i++) {
        for (let j = 0; j < subNavsRoutes.length; j++) {
          if (breadcrumbRoutes[i].includes(`${this.bloc.id}/${subNavsRoutes[j].path}`) && !this.activeItems[j]) { activeItemIndex = j; }
        }
      }
      if (activeItemIndex >= 0) {
          this._navigationService.setActive(activeItemIndex);
      }
    });
  }

  ngAfterViewInit() {
    if (this.bloc && this.bloc.label && this.bloc.label !== 'Support') {
      this.bloc.label = this.bloc.label.toLowerCase();
      this.bloc.label = this.bloc.label[0].toUpperCase() + this.bloc.label.slice(1).toUpperCase();
    }
    this.handleNavOverflow();
    this.ref.detectChanges();
  }

  ngDoCheck() {
    this.checkNavOverflow() ? this.handleNavOverflow() : null;
    if (this.subNavs && !this.visibleSubNavs.length) {
      this.visibleSubNavs = _.cloneDeep(this.subNavs);
      //Desactiver contrat d'échange
      if(this.bloc && this.bloc.id == "facturation"){
        this.visibleSubNavs = this.visibleSubNavs.filter(subNav => subNav.id != 'contratEchange')
      }
    }
    this.ref.detectChanges();
  }

  changeLang(lang) {
    this.translate.use(lang);
    this.currentLang = lang;
  }

  navigateBack() {
    if (this.user.dataType === 'utilisateur') {
      this._router.navigate(['/orga-board']);
    } else if (this._currentUrl.includes('/support/tickets')) {
      if (this._currentUrl !== '/support/tickets/list' && this._currentUrl.includes('/support/tickets')) {
        this._router.navigate(['//support/tickets/list']);
      } else {
        this._previousUrl ? this._router.navigate([this._previousUrl]) : this._router.navigate(['/home-board']);
      }
      this._breadcrumbService.reset();
    } else if (!this._currentUrl) {
      this._breadcrumbService.reset();
      this._router.navigate(['/home-board']);
    } else if (this._previousUrl !== undefined && !this._previousUrl.includes('/login')) {
      this._location.back();
    } else if (this._previousUrl !== undefined && this._previousUrl.includes('/login')) {
      this._confirmationGuard.confirm('Vous allez être déconnecté')
        .subscribe(res => {
          if (res) {
            this._loginService.logout()
              .subscribe(res => {
                this._location.back();
              });
          }
        });
    }
  }

  setActiveItem(index: number) {
    if (index >= this.visibleSubNavs.length) {
      const hiddenIndex = index - this.visibleSubNavs.length;
      const tmp1 = this.hiddenSubNavs[hiddenIndex];
      const tmp2 = this.visibleSubNavs[this.visibleSubNavs.length - 1];
      this.visibleSubNavs[this.visibleSubNavs.length - 1] = tmp1;
      this.hiddenSubNavs[hiddenIndex] = tmp2;
      this._navigationService.setActive(this.visibleSubNavs.length - 1);
    } else {
      this._navigationService.setActive(index);
    }
  }

  changePerimetre(newPerimetre) {
    this._loginService.getUser().then(res => {
      this.user = res;
      const oldPerimetre = { ...this.perimetre };
      if ((newPerimetre != null && newPerimetre.dataType === 'organisation' && this.user.accessOrga)
          || (newPerimetre != null && newPerimetre.dataType !== 'organisation')) {
        this.perimetre = newPerimetre;
        this.perimetreString = newPerimetre.nom;
        if (this.perimetre.nom !== oldPerimetre.nom) {
          if (this.perimetre.dataType === 'organisation') {
            if (this.perimetre.sites) { sessionStorage.setItem('sites', JSON.stringify(this.perimetre.sites)); }
            sessionStorage.setItem('organisation', JSON.stringify(this.perimetre));
          }
          this.sites = JSON.parse(sessionStorage.getItem('sites'));
          this.sites = this.sites.sort((a, b) => a.nom.localeCompare(b.nom));
          this.organisation = JSON.parse(sessionStorage.getItem('organisation'));
          this._dbService.getDatabaseFromSession();
          this._perimetreService.changePerimetre(this.perimetre);
        }
      }
    });
  }

  navigateHome() {
    this._breadcrumbService.reset();
    this._router.navigate(['home-board']);
  }

  navigateSupportTickets() {
    this._breadcrumbService.reset();
    this._router.navigate(['support/tickets/list']);
  }

  navigate(id, index, childId?) {
    const routes = _.flattenDeep(this.parametres.routes);
    const route: any = childId === undefined ? _.find(routes, ['id', id]) : _.find(routes, ['id', childId]);
    if (this.perimetre && this.perimetre.dataType !== 'site' &&
        this.parametres.modulesPerimetreRestreint.map(routeItem => routeItem[1]).indexOf(route) >= 0) {
      this._confirmationGuard.alert('Sélectionner un site dans le sélecteur de périmètre.');
    } else if (!_.includes(this._location.path(), route)) {
      if (childId) {
        index = _.findIndex(this.subNavs, nav => nav.id === id);
      }
      this.setActiveItem(index);
      this._breadcrumbService.reset();
      this._router.navigate([this._router.url.split('/')[1] + '/' + route.path]);
    }
  }

  handleNavOverflow() {
    if (this.checkNavOverflow()) {
      const tmp = this.visibleSubNavs.pop();
      tmp ? this.hiddenSubNavs = _.concat([tmp], this.hiddenSubNavs) : null;
    }
  }

  checkNavOverflow() {
    const el = document.getElementById('nav-section-menu');
    if (el) {
      const curOverflow = el.style.overflow;

      if (!curOverflow || curOverflow === 'visible') {
        el.style.overflow = 'hidden';
      }
      let isOverflowing = el.clientWidth < el.scrollWidth - 1
          || el.clientHeight < el.scrollHeight - 1;

      el.style.overflow = curOverflow;
      if (this.subNavs.filter(nav => nav.children != undefined).length) {
        isOverflowing = el.scrollHeight / el.scrollWidth < el.offsetHeight / el.offsetWidth;
      }
      return isOverflowing;
    }
  }

  ngOnDestroy() {
    this._navigationService.initialiseActiveItems();
    this.perimetreSubscription.unsubscribe();
    this.activeItemsSubscription.unsubscribe();
    this.breadcrumbSubscription.unsubscribe();
  }

  logout() {
    this._loginService.logout()
      .subscribe(res => {
        this._loginService.removeLoggedUser();
        this._router.navigate(['/login']);
      });
  }






}
