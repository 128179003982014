import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BvTranslateService } from 'global/services/bv-translate/bv-translate.service';
import * as _ from 'lodash';
import * as uuid from 'uuid/v4';
import { AlignRule } from '../enums/align-rule';
import { ICell } from '../interfaces/ICell';
import { IColumn } from '../interfaces/IColumn';
import { IRow } from '../interfaces/IRow';
import { Column } from '../models/Column';
import { FilterService } from './FilterService';
import { PaginationService } from './PaginationService';
import { SortService } from './SortService';


@Injectable()
export class DataService {
    private columns_: Array<Column>;
    private rows_: Array<IRow>;
    private selectedRows_: Array<IRow>;
    private rowSelectionEnabled_: boolean;
    private rowId?: any;
  private selectionType: string;

    constructor(
        private paginationService_: PaginationService,
        private sortService_: SortService,
        private filterService_: FilterService,
        private bvTranslateService: BvTranslateService,
        private translateService: TranslateService
    ) {
        this.columns_ = [];
        this.rows_ = [];
        this.rowSelectionEnabled_ = false;
        this.selectedRows_ = [];
    }

    /**
     * Remove column
     */
    removeColumns() {
        this.columns_ = [];
    }

    /**
     * Add columns_
     * @param columns_ - columns_ to add
     */
    addColumns(columns_: Array<IColumn>) {
        _.each(columns_, (aColumn) => {
            const col = new Column();
            col.dataKey = aColumn.dataKey.trim();
            col.name = aColumn.name;
            col.align = aColumn.align || AlignRule.Left;
            col.exclude = aColumn.exclude || false;
            col.list = aColumn.list;
            if (aColumn.filterable) { col.filterable = aColumn.filterable; }
            this.columns_.push(col);
        });
    }

    addRowId(rows_: Array<any>){
        rows_ = rows_.map(row => {
            row._id = row._id ? row._id : row.id ? row.id : uuid();
        })
    }

    /**
     * Add rows_
     * @param rows_ - rows_ to add
     */
    addRows(rows_: Array<any>) {
        if (rows_) {
            rows_ = rows_.map(row => {
                row._id = row._id ? row._id : uuid();
                return row;
            });
        }
        this.rows_ = [];
        _.each(rows_, (aRow: any, rowIndex: number) => {
            const newRow: Array<ICell> = [];
            _.each(this.columns_, (aColumn: IColumn) => {
                newRow.push({
                    dataKey: aColumn.dataKey.trim(),
                    value: this.evalColumn(aColumn.dataKey.trim(), aRow)
                });
            });
            if (aRow) {
                if (this.rowId) {
                    this.rows_.push({
                        index: rowIndex,
                        value: newRow,
                        rowId: aRow[this.rowId]
                    });
                } else {
                    this.rows_.push({
                        index: rowIndex,
                        value: newRow,
                        rowId: aRow._id
                    });
                }
            }
        });
    }

    setRowSelectionType(type:string){
        this.selectionType = type ? type : 'multiple';
    }

    setSelectedRows(rows_: Array<any>) {
        if (this.rows_ && this.rows_.length) {
            this.selectedRows_ = [];
            const rowId = this.rowId ? this.rowId : '_id';
            this.rows_.forEach(row => {
                if (this.selectionType == 'multiple') {
                    if (rows_.map(r => _.get(r, rowId)).includes(row.rowId)) {
                        this.selectRow(row);
                    } else {
                        if (_.get(rows_[0], rowId) == row.rowId) {
                            this.selectRow(row);
                        }
                    }
                }
            });
            this.rows_ = _.cloneDeep(this.rows_);
        }
    }

    /**
     * Select all showed rows
     */
    selectAllRows() {
        if (this.selectionType == 'multiple') {
            _.each(this.getRows(), (aRow: IRow) => {
                aRow.selected = true;
            });
            this.selectedRows_ = this.getRows();
        }
    }

    /**
     * Deselect all showed rows
     */
    deselectAllRows() {
        _.each(this.getRows(), (aRow: IRow) => {
            aRow.selected = false;
        });
        this.selectedRows_ = [];
    }

    getSelectedRows(): Array<IRow> {
        return this.selectedRows_;
    }

    getColumns(): Array<Column> {
        return this.columns_;
    }

    /**
     * Get showed rows
     */
    getRows(): Array<IRow> {
        // filter rows
        let rows_ = this.filterService_.transforms(this.rows_);
        // sorting rows_
        rows_ = this.sortService_.transformRows(rows_);
        // paginating rows_
        rows_ = this.paginationService_.transformRows(rows_);
        return rows_;
    }

    /**
     * Get cell data
     * @param column - cell column
     * @param row - cell row
     */
    getCellData(column: IColumn, row: IRow) {
        const value = _.find(row.value, (aCell: ICell) => {
            return aCell.dataKey === column.dataKey;
        });
        if (column.dataKey === 'site.nom' && value)  {
            value.value = value.value || this.translateService.instant('app.global.allSites');
        } else if (value && _.isNil(value.value)) {
            value.value = '-';
        } else if (value && _.isArray(value.value)) {
            value.value = _.join(value.value.map(v => this.translateValue(column.list, v)), ' | ');
        } else if(value) {
            value.value = this.translateValue(column.list, value.value);
        }
        return value;
    }

    isRowSelectionMultiple(): boolean{
        return this.selectionType == 'multiple';
    }

    /**
     * Define row selection property
     * @param isEnabled - selection property
     */
    setRowSelectionEnabled(isEnabled: boolean) {
        this.rowSelectionEnabled_ = !!isEnabled;
    }

    /**
     * check wether select is enabled or not
     * @return row selection proerty
     */
    isRowSelectionEnabled(): boolean {
        return this.rowSelectionEnabled_;
    }

    /**
     * Add row to selection list
     * @param row - row to add
     */
    selectRow(row: IRow) {
        row.selected = true;
        if(this.isRowSelectionMultiple()) {
            this.selectedRows_.push(row);
        } else {
            this.selectedRows_ .forEach(row => this.deselectRow(row));
            this.selectedRows_ = [row];
        }
    }

    /**
     * Remove row from selection list
     * @param row - row to remove
     */
    deselectRow(row: IRow) {
        row.selected = false;
        this.selectedRows_ = this.selectedRows_.filter((r: IRow) => r.rowId != row.rowId);
    }

    /**
     * Get table data row id
     */
    getRowId(): any {
        return this.rowId;
    }

    /**
     * Set table data row id
     * @param rowId - data row id
     */
    setRowId(rowId: any) {
        this.rowId = rowId;
    }

    private translateValue(list: [{id: string, label: string}], value: string): string {
        if (!list) {
            return value;
        }
        const translation = _.find(list, ['id', value]);
        return translation ? translation.label : value;
    }

    private evalColumn(exp: string, aRow: any): any {
        const reg = new RegExp('^\{.*\}$');
        if (!reg.test(exp)) { return _.get(aRow, exp); }
        else {
            const help = exp.match('^\{(.*)\}$');
            const expression = help[1].split('/').map((exp, i, arr) => {
                return i < arr.length - 1 ? _.get(aRow, exp) + '/' : _.get(aRow, exp);
            }).reduce((a, b) => a + b, '');
            let value = eval(expression);
            value = Number.isNaN(value) || !Number.isFinite(value) ? undefined : value.toFixed(2);
            return value ? Number.parseFloat(value) : value;
        }
    }

}
