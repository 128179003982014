import { SiteDocumentDAO } from 'global/models/dao/site-document';
import { Adresse } from 'global/models/adresse';
import { Contact } from 'global/models/contact';

export class TiersDAO extends SiteDocumentDAO{
	public codeTiers : string;
	public numPacage: string;
	public nom : string;
	public adresse : Adresse = new Adresse();
	public typeTiers : string[];
	public typeActivite : string[];
	public contactFacturation : Contact = new Contact();
	public contact : Contact = new Contact();
	public contact2 : Contact = new Contact();
	public siret : string;
	public numTVA : string;
	public numAgrementSanitaire : string;
	public domiciliationBancaire : string;
	public rib : string;
	public bic : string;
	public iban : string;
	public numProducteur: string;
	public numCompteClient : string;
	public numCompteFournisseur : string;
	public envoiFacture : string;
	public numRecepisse : string;
	public limiteValidite : any;
	public commentaires : string;
	public notDisplayInDropdowns : boolean = false;
	public destinatairesFacture: any[];
	public destinatairesLogistique: any[];
	public sameAdresse : boolean = true;
	public adresseFacturation : Adresse = new Adresse();
	public regroupement: string;
	public durable: boolean = false;

	constructor(){
		super();
		this.dataType = 'tiers';
	}

}
