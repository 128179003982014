import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'historique-trace',
  templateUrl: './historique-trace.component.html',
  styleUrls: ['./historique-trace.component.scss']
})
export class HistoriqueTraceComponent implements OnInit {

  @Input()
  title: string;
  @Input()
  model: any;
  constructor() { }

  ngOnInit() {
    if(this.model){
      if(this.model.createdBy == 'user.ftp@biogasview.com'){
        this.model.createdBy = "Interface Biogasview"
      }
      if(this.model.modifiedBy == 'user.ftp@biogasview.com'){
        this.model.modifiedBy = "Interface Biogasview";
      }
    }
  }

  show(): boolean{
    return this.model != undefined && (this.model.createdBy != undefined || this.model.modifiedBy != undefined);
  }

}
