import { NouvelElementComponent } from 'journal/modals/nouvel-element/nouvel-element/nouvel-element.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModule, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TreeModule } from 'angular-tree-component';
import { FullCalendarModule } from 'ng-fullcalendar';
import { DataTableModule } from 'table/data-table.module';
import { ToggleTableModule } from 'toggle-table/toggle-table.module';

import { PatternValidatorDirective } from './directives/pattern-validator.directive';
import { ProvideParentForm } from './directives/provide-parent-form';
import { MegaNavigationDirective } from './directives/mega-navigation';

import { NavigationComponent } from './components/navigation/navigation.component';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { MegaNavigationComponent } from './components/mega-navigation/mega-navigation.component';

import { NgbDateCustomParserFormatter } from './services/ngb-date-custom-parser/ngb-date-custom-parser-formatter';
import { CustomDatepickerI18n, I18n } from './services/custom-datepicker-i18n/custom-datepicker-i18n';
import { DatePickerAdapter } from './services/date-picker-adapter/date-picker-adapter.service';
import { TimepickerAdapter } from './services/timepicker-adapter/timepicker-adapter.service';
import { LoginService } from './services/login/login.service';
import { DocumentService } from './services/document/document.service';
import { DatabaseService } from './services/database/database.service';
import { XhrInterceptor } from './services/xhrinterceptor/xhrinterceptor';
import { AlertModalComponent, ConfirmModalComponent } from './services/confirmation-guard/confirmation-guard.service';

import { JoinArrayPipe } from './pipes/join-array.pipe';
import { SafeUrlPipe } from './pipes/safe-url-sanitize.pipe';
import { SelecteurPerimetreInformComponent } from './components/selecteur-perimetre-inform/selecteur-perimetre-inform.component';
import { ExportDataComponent } from './components/export-data/export-data.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { CalendrierComponent } from './components/calendrier/calendrier.component';
import { EventModalComponent } from './components/calendrier/event-modal/event-modal.component';
import { NumberFixedPipe } from './pipes/number-fixed.pipe';
import { EllipsisAnimationComponent } from './components/ellipsis-animation/ellipsis-animation.component';
import {TranslateModule} from '@ngx-translate/core';
import {PopupModalComponent} from 'global/modals/popup-modal/popup-modal.component';
import { HistoriqueTraceComponent } from './components/historique-trace/historique-trace.component';

@NgModule({
    imports: [
        FullCalendarModule,
        CommonModule,
        RouterModule.forChild([]),
        DataTableModule,
        FormsModule,
        NgbModule,
        HttpClientModule,
        ToggleTableModule,
        ColorSketchModule,
        TreeModule.forRoot(),
        TranslateModule,
    ],
    declarations: [
        PatternValidatorDirective,
        ProvideParentForm,
        NavigationComponent,
        CustomInputComponent,
        JoinArrayPipe,
        SafeUrlPipe,
        BreadcrumbComponent,
        SelecteurPerimetreInformComponent,
        ConfirmModalComponent,
        PopupModalComponent,
        AlertModalComponent,
        ExportDataComponent,
        ToastMessageComponent,
        CalendrierComponent,
        EventModalComponent,
        NumberFixedPipe,
        MegaNavigationComponent,
        MegaNavigationDirective,
        NouvelElementComponent,
        EllipsisAnimationComponent,
        HistoriqueTraceComponent
    ],
    exports: [
        NavigationComponent,
        CustomInputComponent,
        BreadcrumbComponent,
        SelecteurPerimetreInformComponent,
        SafeUrlPipe,
        NumberFixedPipe,
        ExportDataComponent,
        ToastMessageComponent,
        CalendrierComponent,
        EventModalComponent,
        MegaNavigationComponent,
        MegaNavigationDirective,
        EllipsisAnimationComponent,
        HistoriqueTraceComponent
    ],
    providers: [
        {
            provide: NgbDateAdapter, useClass: DatePickerAdapter
        },
        {
            provide: NgbTimeAdapter, useClass: TimepickerAdapter
        },
        {
            provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter
        },
        I18n,
        {
            provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n
        },
        LoginService,
        DatabaseService,
        DocumentService,
        { provide: HTTP_INTERCEPTORS, useClass: XhrInterceptor, multi: true }
    ],
    entryComponents: [
        ConfirmModalComponent,
        AlertModalComponent,
        ExportDataComponent,
        EventModalComponent,
        NouvelElementComponent,
        PopupModalComponent
    ],
})
export class GlobalModule {
}
