import { SupportTicketModalComponent } from './components/support-tickets/support-ticket-modal/support-ticket-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TreeModule } from 'angular-tree-component';
import { PlotlyModule } from 'angular-plotly.js';
import { DataTableModule } from 'table/data-table.module';

import { TracabiliteModule } from 'tracabilite/tracabilite.module';
import { ExploitationModule } from 'exploitation/exploitation.module';
import { GlobalModule } from 'global/global.module';
import { AppRoutingModule } from './app-routing.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { AppComponent } from './app.component';
import { HomeBoardComponent } from 'home-board/home-board.component';
import { LoginComponent } from './components/login/login.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { OrgaBoardComponent } from './components/orga-board/orga-board.component';
import { TimeoutComponent } from './components/timeout/timeout.component';
import { UserPasswordComponent } from './components/user/user-password/user-password.component';
import { UserHomeComponent } from './components/user/user-home/user-home.component';
import { UserProfilComponent } from './components/user/user-profil/user-profil.component';
import { UserAccessComponent } from './components/user/user-access/user-access.component';
import { SupportTicketAddComponent } from './components/support-tickets/support-ticket-add/support-ticket-add.component';
import { SupportTicketListComponent } from './components/support-tickets/support-ticket-list/support-ticket-list.component';
import { SupportTicketUpdateComponent } from './components/support-tickets/support-ticket-update/support-ticket-update.component';

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeBoardComponent,
    LoginComponent,
    PasswordForgotComponent,
    PasswordResetComponent,
    OrgaBoardComponent,
    TimeoutComponent,
    UserPasswordComponent,
    UserHomeComponent,
    UserProfilComponent,
    UserAccessComponent,
    SupportTicketAddComponent,
    SupportTicketListComponent,
    SupportTicketUpdateComponent,
    SupportTicketModalComponent
  ],
  imports: [
    BrowserAnimationsModule,
    TracabiliteModule,
    ExploitationModule,
    AppRoutingModule,
    GlobalModule,
    NgbModule,
    FormsModule,
    DataTableModule,
    TreeModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
    // PlotlyModule
  ],
  providers: [
    HttpClient,
    DatePipe
  ],
  bootstrap: [AppComponent],
  exports: [
    UserPasswordComponent,
    UserHomeComponent,
    UserProfilComponent,
    UserAccessComponent,
    TranslateModule,
  ],
  entryComponents: [SupportTicketModalComponent]
})
export class AppModule { }
