import { Injectable } from '@angular/core';
import {ParametresItService} from 'global/services/parametres/parametres-it.service';
import {ParametresEnService} from 'global/services/parametres/parametres-en.service';
import {ParametresService} from 'global/services/parametres/parametres.service';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class BvTranslateService {

  currentLang;

  constructor(
      private parametresFrService: ParametresService,
      private parametresItService: ParametresItService,
      private parametresEnService: ParametresEnService,
      private translateService: TranslateService
  ) {
    this.currentLang = this.translateService.currentLang;
  }

  getParametres(currentLang?: string) {
    if (currentLang) {
      this.setLang(currentLang);
    }
    switch (this.currentLang) {
      case 'it': {
        return this.parametresItService.getParametres();
        break;
      }
      case 'en' : {
        return this.parametresEnService.getParametres();
        break;
      }
      default : {
        return this.parametresFrService.getParametres();
        break;
      }
    }
  }

  getLocale(currentLang?: string) {
    if (currentLang) {
      this.setLang(currentLang);
    }
    switch (this.currentLang) {
      case 'it': {
        return 'it-IT';
      }
      case 'fr' : {
        return 'fr-FR';
      }
    }
  }

  getTypeTiers(id) {
    switch (this.currentLang) {
      case 'it': {
        return _.find(this.parametresItService.getParametres().typeTiers, ['id', id]).label;
      }
      case 'fr' : {
        return _.find(this.parametresFrService.getParametres().typeTiers, ['id', id]).label;
      }
    }
  }

  setLang(lang: string) {
    this.currentLang = lang;
  }
}
