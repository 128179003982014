import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BvTranslateService } from 'global/services/bv-translate/bv-translate.service';
import { defer, pipe, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Reception } from 'appro/models/dto/reception';
import { Site } from 'global/models/dto/site';
import { CustomDatepickerI18n } from 'global/services/custom-datepicker-i18n/custom-datepicker-i18n';
import { DatabaseService } from 'global/services/database/database.service';
import { CommonDatabaseQueryService } from 'global/services/common-database-query/common-database-query.service';
import { DocumentService } from 'global/services/document/document.service';
import { Gisement } from '../../../../models/dto/gisement';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ReceptionService extends CommonDatabaseQueryService {
  private receptions: Reception[];
  private _headers: any[];
  private _mailHeader: any[];

  cpc = ['MS/MB', 'MO/MB', 'PM/MB stand', 'Ntot'];

  constructor(
    private dateService: CustomDatepickerI18n,
    _databaseService: DatabaseService,
    private _documentService: DocumentService,
    private _datePipe: DatePipe,
    private translateService: TranslateService,
    private bvTranslateService: BvTranslateService
  ) {
    super(_databaseService, {
      all: 'Reception/all',
      fetch_all: 'Reception/fetch_all',
      joins: 'Reception/joins',
      by_site: 'Reception/by_site',
      tonnage_by_stock_and_date: 'Reception/tonnage_by_stock_and_date',
      by_site_and_numReception: 'Reception/by_site_and_numReception',
      linked_documents: 'Reception/linked_documents',
      by_date_and_site: 'Reception/by_date_and_site',
      standby_by_date: 'Reception/standby_by_date',
      standby_by_date_and_site: 'Reception/standby_by_date_and_site'
    });
    this._headers = [
      { nom: 'numPesee', label: "Num. pesée" },
      { nom: "dateReception", label: "Date" },
      { nom: "heureReception", label: "Heure" },
      { nom: "gisement.nomParcelle", label: "Gisement" },
      { nom: "gisement.transporteur.nom", label: "Transporteur" },
      { nom: "poidsNet", label: "Poids net (t)" },
      { nom: 'MS/MB', label: 'MS/MB (%)' },
      { nom: 'MO/MB', label: 'MO/MB (%)' },
      { nom: 'PM/MB', label: 'PM/MB (Nm3/t)' },
      {nom: "Ntot", label : "Azote totale (en g/kg MB)"},
      { nom: "statutFacturationClient", label: "Fact. client" },
      { nom: "statutFacturationFournisseur", label: "Fact. fourn." }
    ]

  }

  public get headers() {
    return [
      { nom: 'numPesee', label: this.translateService.instant('app.global.headers.pesee') },
      { nom: 'dateReception', label: this.translateService.instant('app.global.headers.date') },
      { nom: 'heureReception', label: this.translateService.instant('app.global.headers.heure') },
      { nom: 'destination', label: this.translateService.instant('app.global.headers.destination') },
      { nom: 'gisement.nom', label: this.translateService.instant('app.global.headers.gisement') },
      { nom: 'gisement.transporteur.nom', label: this.translateService.instant('app.global.headers.transporteur') },
      { nom: 'poidsNet', label: this.translateService.instant('app.global.headers.poidsNet') },
      { nom: 'MS/MB', label: this.translateService.instant('app.global.headers.MS/MB%') },
      { nom: 'MO/MB', label: this.translateService.instant('app.global.headers.MO/MB%') },
      { nom: 'PM/MB stand', label: this.translateService.instant('app.global.headers.PM/MB%') },
      { nom: 'statutFacturationClient', label: this.translateService.instant('app.global.headers.factClient'), list: this.bvTranslateService.getParametres().statusReceptionFacturation },
      { nom: 'statutFacturationFournisseur', label: this.translateService.instant('app.global.headers.factFournisseur'), list: this.bvTranslateService.getParametres().statusReceptionFacturation },
      { nom: 'statutReception', label: this.translateService.instant('app.global.headers.statut'), list: this.bvTranslateService.getParametres().statusReception },
    ];
  }

  public get mailHeader() {
    return [
      { nom: 'docName', label: this.translateService.instant('app.global.headers.docName') },
      { nom: 'numPesee', label: this.translateService.instant('app.global.headers.numPesee') },
      { nom: 'date', label: this.translateService.instant('app.global.headers.date') },
      { nom: 'gisement', label: this.translateService.instant('app.global.headers.gisement') },
      { nom: 'destinataire', label: this.translateService.instant('app.global.headers.destinataire') },
    ];
  }

  public get(id: string): any {
    return super.joins(id)
      .then(res => {

        let reception: Reception;

        for (let i = 0; i < res.rows.length; i++) {
          if (res.rows[i].key[1] == 0 && res.rows[i].doc) {
            delete res.rows[i].doc.siteId;
            reception = res.rows[i].doc as Reception;
          } else if (res.rows[i].key[1] == 1 && res.rows[i].doc) { reception.site = res.rows[i].doc as Site; }
        }
        if (reception.gisement && reception.gisement.cpc) {
          reception.gisement.cpc = reception.gisement.cpc.map(cpc => {
            cpc.nom = this.upgradeGisementCPC(cpc.nom);
            return cpc;
          });
        }
        return reception;
      })
      .catch(error => defer(() => Promise.reject(error)));
  }

  public documents(id: string): any {
    return this._dbService.db
      .query(
        'GedDocument/by_documents',
        {
          include_docs: true,
          key: id
        }
      )
      .then(res => res.rows.map(d => d.doc))
      .catch(error => defer(() => Promise.reject(error)));
  }


  public add(reception: Reception) {
    const user = JSON.parse(sessionStorage.getItem('loggedUser'));
    reception.createdBy = user.email;
    reception.modifiedBy = user.email;
    reception.createdOn = new Date().getTime();
    reception.modifiedOn = new Date().getTime();
    if (reception._id == undefined) { reception._id = 'reception_' + uuid(); }
    if (reception.gisement.site) {
      delete reception.gisement.site;
    }
    if (reception.gisement.stockIntrant && reception.gisement.stockIntrant.site) {
      delete reception.gisement.stockIntrant.site;
    }
    if (reception.gisement.ouvrageIncorporation && reception.gisement.ouvrageIncorporation.site)
      delete reception.gisement.ouvrageIncorporation.site;
    return this._dbService.db.put(reception.toDao());
  }

  public update(reception: Reception) {
    const user = JSON.parse(sessionStorage.getItem('loggedUser'));
    reception.modifiedBy = user.email;
    reception.modifiedOn = new Date().getTime();
    reception.exported = false;
    reception.thirdPartyExport = false;
    if (reception.gisement && reception.gisement.site) {
      delete reception.gisement.site;
    }
    if (reception.gisement.stockIntrant && reception.gisement.stockIntrant.site) {
      delete reception.gisement.stockIntrant.site;
    }
    if (reception.gisement.ouvrageIncorporation && reception.gisement.ouvrageIncorporation.site) {
      delete reception.gisement.ouvrageIncorporation.site;
    }
    if (reception._id == undefined || reception._rev == undefined) {
      return defer(() => Promise.reject('for updating neither _id, nore _rev can be undefined or null'));
    }
    return this._dbService.db.get(reception._id)
      .then(res => {
        reception._rev = res._rev;
        return this._dbService.db.put(reception.toDao());
      });
  }

  public upSert(reception: any) {
    if (reception._id) { return this.update(this.toReception(reception)); } 
    else { return this.add(this.toReception(reception)); }
  }

  public updateStatut(receptions: Reception[], type: string= 'client') {
    const promises = receptions.map(reception => {
      return this._dbService.db.get(reception._id)
        .then(doc => {
          if (type == 'client') {
            doc.statutFacturationClient = reception.statutFacturationClient;
          } else {
            doc.statutFacturationFournisseur = reception.statutFacturationFournisseur;
          }
          return doc;
        });
    });
    Promise.all(promises)
      .then(receptions => {
        return this._dbService.db.bulkDocs(receptions);
      });
  }

  public bulkReception(receptions: Reception[]): any {
    const receptionDAO = receptions.filter(recep => recep != undefined).map(reception => {
      if (!reception._id) { reception._id = 'reception_' + uuid(); }
      return reception.toDao();
    });
    return this._dbService.db.bulkDocs(receptionDAO);
  }



  public checkIfExists(reception: Reception) {
    const siteId = reception.site ? reception.site.id || reception.site._id : reception.site;
    return this._dbService.db.query(
      'Reception/by_site_and_numReception',
      {
        include_docs: true,
        key: [siteId, reception.numReception]
      }
    ).then(res => {
      let sameObject = false;
      if (res.rows.length == 0) { return false; } else if (reception._id) {
        res.rows.forEach(row => {
          if (row.id == reception._id) { sameObject = row.id == reception._id; }
        });
        return !sameObject;
      }
      return true;
    });
  }

  /**
  * Joins by site
  * @param site - site
  * @param skip - number of rows to skip
  * @param limit - max number of rows to return
  * @param desc - descending sort param
  */
  public fetchAll(site: string = undefined, skip: number = 0, limit: number = 100, desc: boolean = false): any {
    return super.fetchAll(site, skip, limit, desc)
      .then(res => {
        let reception: Reception;
        let receptions = new Array<Reception>();
        receptions = res.rows.map(row => row.doc);
        return this._dbService.db.query('Site/all')
          .then(sites => {
            sites = sites.rows.map(site => site.value);
            receptions = receptions.map(recep => {
              recep.datePesee1 = recep.datePesee1 && (typeof recep.datePesee1 == 'string') ? moment(recep.datePesee1, 'DD/MM/YYYY').format('YYYY/MM/DD') : recep.datePesee1;
              recep.datePesee2 = recep.datePesee2 && (typeof recep.datePesee2 == 'string') ? moment(recep.datePesee2, 'DD/MM/YYYY').format('YYYY/MM/DD') : recep.datePesee2;
              const site = sites.find(s => s._id === recep.site);
              if (site) {
                recep.site = site;
              }
              return recep;
            });
            receptions = _.sortBy(receptions, [function (recep) { return - recep.dateReception; }]);
            let total_rows = res.rows.length;
            if (res.rows.length >= limit) {
              total_rows = (res.total_rows - res.offset) > limit ? res.total_rows - res.offset : limit;
            }
            return { rows: receptions, total_rows: total_rows };
          });
      })
      .catch(error => defer(() => Promise.reject(error)));
  }

  /**
  * Joins by site
  * @param site - site
  * @param skip - number of rows to skip
  * @param limit - max number of rows to return
  * @param desc - descending sort param
  */
  public bySite(site_: string = undefined, skip: number = 0, limit: number = 10000, desc: boolean = false): any {
    return super.bySite(site_, skip, limit, desc)
      .then(res => {
        let reception: Reception;
        let site: Site;
        let receptions = new Array<Reception>();
        const sites = new Array<Site>();
        for (let i = 0; i < res.rows.length; i++) {
          if (res.rows[i].key[1] == 0 && res.rows[i].doc) {
            delete res.rows[i].doc.siteId;
            reception = res.rows[i].doc as Reception;
            reception.datePesee1 = reception.datePesee1 && (typeof reception.datePesee1 == 'string') ? moment(reception.datePesee1, 'DD/MM/YYYY').format('YYYY/MM/DD') : reception.datePesee1;
            reception.datePesee2 = reception.datePesee2 && (typeof reception.datePesee2 == 'string') ? moment(reception.datePesee2, 'DD/MM/YYYY').format('YYYY/MM/DD') : reception.datePesee2;
            receptions.push(reception);
          } else if (res.rows[i].key[1] == 1 && res.rows[i].doc) {
            site = res.rows[i].doc as Site;
            sites.push(site);
          }
        }
        if (site) {
          receptions = receptions.filter(reception => reception.site == site);
        }
        sites.forEach((site, index) => {
          receptions[index].site = site;
        });
        if (site_) {
          receptions = receptions.filter((reception: any) => reception.site && reception.site._id == site_);
        }
        res.rows = _.sortBy(receptions, [function (recep) { return - recep.dateReception; }]);
        return res.rows;
      })
      .catch(error => defer(() => Promise.reject(error)));
  }

  gisementPoidsNetByTiers(gisement: string, transporteur: string, dateDebut: string, dateFin: string, reduce: boolean = true): any {
    const option: any = {
      startkey: [transporteur, gisement, dateDebut],
      endkey: [transporteur, gisement, dateFin],
      reduce: true
    };
    if (!reduce) {
      option.include_docs = true;
      option.reduce = false;
    }
    return this._dbService.db
      .query('Reception/gisement_poidsnet', option);
  }

  gisementTonnesMSByTiers(gisement: string, transporteur: string, dateDebut: string, dateFin: string, reduce: boolean = true): any {
    const option: any = {
      startkey: [transporteur, gisement, dateDebut],
      endkey: [transporteur, gisement, dateFin],
      reduce: true
    };
    if (!reduce) {
      option.include_docs = true;
      option.reduce = false;
    }
    return this._dbService.db
      .query('Reception/gisement_tonnesms', option);
  }

  gisementNbreColisByTiers(gisement: string, transporteur: string, dateDebut: string, dateFin: string, reduce: boolean = true): any {
    const option: any = {
      startkey: [transporteur, gisement, dateDebut],
      endkey: [transporteur, gisement, dateFin],
      reduce: true
    };
    if (!reduce) {
      option.include_docs = true;
      option.reduce = false;
    }
    return this._dbService.db
      .query('Reception/gisement_nbrecolis', option);
  }

  gisementPoidsNetByArticle(gisement: string, dateDebut: string, dateFin: string, reduce: boolean = true): any {
    const option: any = {
      startkey: [gisement, dateDebut],
      endkey: [gisement, dateFin],
      reduce: true
    };
    if (!reduce) {
      option.include_docs = true;
      option.reduce = false;
    }
    return this._dbService.db
      .query('Reception/gisement_par_poidsnet', option);
  }

  gisementTonnesMSByArticle(gisement: string, dateDebut: string, dateFin: string, reduce: boolean = true): any {
    const option: any = {
      startkey: [gisement, dateDebut],
      endkey: [gisement, dateFin],
      reduce: true
    };
    if (!reduce) {
      option.include_docs = true;
      option.reduce = false;
    }
    return this._dbService.db
      .query('Reception/gisement_par_tonnesms', option);
  }

  gisementNbreColisByArticle(gisement: string, dateDebut: string, dateFin: string, reduce: boolean = true): any {
    const option: any = {
      startkey: [gisement, dateDebut],
      endkey: [gisement, dateFin],
      reduce: true
    };
    if (!reduce) {
      option.include_docs = true;
      option.reduce = false;
    }
    return this._dbService.db
      .query('Reception/gisement_par_nbrecolis', option);
  }

  tiersPoidsNetByTiers(transporteur: string, dateDebut: string, dateFin: string, reduce: boolean = true): any {
    const option: any = {
      startkey: [transporteur, dateDebut],
      endkey: [transporteur, dateFin],
      reduce: true
    };
    if (!reduce) {
      option.include_docs = true;
      option.reduce = false;
    }
    return this._dbService.db
      .query('Reception/tiers_poidsnet', option);
  }

  tiersTonnesMSByTiers(transporteur: string, dateDebut: string, dateFin: string, reduce: boolean = true): any {
    const option: any = {
      startkey: [transporteur, dateDebut],
      endkey: [transporteur, dateFin],
      reduce: true
    };
    if (!reduce) {
      option.include_docs = true;
      option.reduce = false;
    }
    return this._dbService.db
      .query('Reception/tiers_tonnesms', option);
  }

  tiersNbreColisByTiers(transporteur: string, dateDebut: string, dateFin: string, reduce: boolean = true): any {
    const option: any = {
      startkey: [transporteur, dateDebut],
      endkey: [transporteur, dateFin],
      reduce: true
    };
    if (!reduce) {
      option.include_docs = true;
      option.reduce = false;
    }
    return this._dbService.db
      .query('Reception/tiers_nbrecolis', option);
  }

  public getWeekTonnageByStockAndDate(stock, dateDebut: any) {
    const tonnages = new Array(7);
    let dateDebutFormat = new Date(dateDebut);
    dateDebutFormat = new Date(dateDebutFormat.getFullYear(), dateDebutFormat.getMonth(), dateDebutFormat.getDate());
    const weekRange = this.dateService.getWeekRangeFromDate(dateDebutFormat);
    const daysStarts = this.dateService.calculateDaysInBetween(weekRange.debut, weekRange.fin);
    const daysEnds = [];
    const dateEnd = new Date(weekRange.fin);
    dateEnd.setHours(23);
    const dateEndTime = dateEnd.getTime();
    const dateStart = daysStarts[0].getTime();
    return this._dbService.db.query(
      'Reception/tonnage_by_stock_and_date',
      {
        include_docs: false,
        start_key: [stock._id, dateStart],
        end_key: [stock._id, dateEndTime],
        group_level: 2
      }
    ).then(res => {
      const rows = res.rows;
      const tonnages = new Array(7);
      let dateW = dateStart;
      for (let i = 0; i < tonnages.length; i++) {
        const row = rows.find(row => {
          return row.key[1] >= dateW && row.key[1] <= dateW + 24 * 60 * 60 * 1000;
        });
        row ? tonnages[i] = row.value.sum : tonnages[i] = 0;
        dateW += 24 * 60 * 60 * 1000;
      }
      return tonnages;
    });
  }

  public delete(reception: Reception) {
    return this._dbService.db.get(reception._id).then(doc => {
      reception._rev = doc._rev;
      return super.linkedDocuments(reception._id).then(res => {
        if (res.rows.length > 0) {
          reception.archived = true;
          return this.update(reception);
        } else {
          reception.notReportable = true;
          return this._dbService.db.put(reception);
        }
      });
    });
  }

  public bulkDelete(receptions: Reception[]) {
    let promises = [], merges = [];
    promises = receptions.map(reception => this.delete(reception));
    const organisation = JSON.parse(sessionStorage.getItem('organisation'));
    const organisationId = organisation.id || organisation._id;
    from(receptions).pipe(
      concatMap(reception => this._documentService.deleteDocuments(organisationId, reception._id))
    ).subscribe(res => {
      console.log(res);
    });
    return Promise.all(promises);
  }

  public bulkUpdate(receptions: Reception[]) {
    let promises = [], merges = [];
    promises = receptions.map(reception => this.upSert(reception));
    return Promise.all(promises);
  }

  public byDate(site: any, start: string, end: string) {
    return this._dbService.db.query(
      'Reception/by_site_and_date',
      {
        include_docs: true,
        startkey: [site, start],
        endkey: [site, end]
      }
    );
  }

  public byDateAndSite(start: string, end: string, site_: any = undefined) {
    const options: any = {
      include_docs: true
    };
    let promise;
    if (site_) {
      options.startkey = [site_, start, 0];
      options.endkey = [site_, end, 1];
      promise = this._dbService.db.query('Reception/by_date_and_site', options);
    } else {
      options.endkey = [end, 1];
      options.startkey = [start, 0];
      promise = this._dbService.db.query('Reception/by_date', options);
    }
    return promise.then(res => {
      let reception: Reception;
      let site: Site;
      let receptions = new Array<Reception>();
      const sites = new Array<Site>();
      for (let i = 0; i < res.rows.length; i++) {
        if ((res.rows[i].key[2] == 0 || res.rows[i].key[1] == 0) && res.rows[i].doc) {
          delete res.rows[i].doc.siteId;
          reception = res.rows[i].doc as Reception;
          reception.datePesee1 = reception.datePesee1 && (typeof reception.datePesee1 == 'string') ? moment(reception.datePesee1, 'DD/MM/YYYY').format('YYYY/MM/DD') : reception.datePesee1;
          reception.datePesee2 = reception.datePesee2 && (typeof reception.datePesee2 == 'string') ? moment(reception.datePesee2, 'DD/MM/YYYY').format('YYYY/MM/DD') : reception.datePesee2;
          receptions.push(reception);
        } else if ((res.rows[i].key[2] == 1 || res.rows[i].key[1] == 1) && res.rows[i].doc) {
          site = res.rows[i].doc as Site;
          sites.push(site);
        }
      }
      sites.forEach((site, index) => {
        receptions[index].site = site;
      });
      if (site_) {
        receptions = receptions.filter((reception: any) => reception.site && reception.site._id == site_);
      }
      res.rows = _.sortBy(receptions, [function (recep) { return - recep.dateReception; }]);
      return res.rows;
    })
      .catch(error => defer(() => Promise.reject(error)));
  }

  public standbyDateAndSite(start: string, end: string, site_: any = undefined) {
    const options: any = {
      include_docs: true
    };
    let promise;
    if (site_) {
      options.startkey = [site_, start, 0];
      options.endkey = [site_, end, 1];
      promise = this._dbService.db.query('Reception/standby_by_date_and_site', options);
    } else {
      options.endkey = [end, 1];
      options.startkey = [start, 0];
      promise = this._dbService.db.query('Reception/standby_by_date', options);
    }
    return promise.then(res => {
      let reception: Reception;
      let site: Site;
      let receptions = new Array<Reception>();
      const sites = new Array<Site>();
      for (let i = 0; i < res.rows.length; i++) {
        if ((res.rows[i].key[2] == 0 || res.rows[i].key[1] == 0) && res.rows[i].doc) {
          delete res.rows[i].doc.siteId;
          reception = res.rows[i].doc as Reception;
          receptions.push(reception);
        } else if ((res.rows[i].key[2] == 1 || res.rows[i].key[1] == 1) && res.rows[i].doc) {
          site = res.rows[i].doc as Site;
          sites.push(site);
        }
      }
      sites.forEach((site, index) => {
        receptions[index].site = site;
      });
      if (site_) {
        receptions = receptions.filter((reception: any) => reception.site && reception.site._id == site_);
      }
      res.rows = _.sortBy(receptions, [function (recep) { return - recep.dateReception; }]);
      return res.rows;
    })
      .catch(error => defer(() => Promise.reject(error)));
  }

  public registre(recep: any[], start: string, end: string, site: string = 'Tous les sites') {
    const receptions = recep.map(res => {
      res.gisement.producteur.adresse = this.getAdresse(res.gisement.producteur.adresse);
      res.gisement.transporteur.adresse = this.getAdresse(res.gisement.transporteur.adresse);
      res.gisement.transporteur.limiteValidite = res.gisement.transporteur.limiteValidite ? this._datePipe.transform(new Date(res.gisement.transporteur.limiteValidite), 'dd/MM/yyyy') : res.gisement.transporteur.limiteValidite;
      res.dateIncorporation = res.dateIncorporation ? this._datePipe.transform(new Date(res.dateIncorporation), 'dd/MM/yyyy') : res.dateIncorporation;
      return [
        { text: res.gisement.nom || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.codeDechet || '', style: 'detail', alignment: 'left' },
        { text: res.dateReception, style: 'detail', alignment: 'left' },
        { text: Intl.NumberFormat().format(res.poidsNet) + ' ', style: 'detail', alignment: 'right' },
        { text: res.gisement.producteur.nom || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.producteur.adresse || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.producteur.siret || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.transporteur.nom || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.transporteur.adresse || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.transporteur.siret || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.transporteur.numRecepisse || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.transporteur.limiteValidite || '', style: 'detail', alignment: 'left' },
        { text: res.dateIncorporation, style: 'detail', alignment: 'left' },
        { text: '', style: 'detail', alignment: 'left' },
        { text: '', style: 'detail', alignment: 'left' },
        { text: '', style: 'detail', alignment: 'left' },
      ];
    });

    receptions.length ? receptions.unshift([
      { text: this.translateService.instant('app.global.headers.site'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.codeDechet'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.date'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.quantite%'), style: 'tableHeader', alignment: 'right' },
      { text: this.translateService.instant('app.global.headers.expediteur'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.adresseExpéditeur'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.nSIRETExpediteur'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.transporteur'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.adresseTransporteur'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.nSIRETTransporteur'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.nRecepisse'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.limiteValiditeRecepisse'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.datePreviTraitement'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.dateRefus'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.motifRefus'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.destinationSiRefus'), style: 'tableHeader', alignment: 'center' }
    ]) : receptions.push([{ text: '-', style: 'detail', alignment: 'center' }]);

    const data = this.registreTemplate({
      site: site,
      exportTime: this._datePipe.transform(new Date(), 'dd/MM/yyyy'),
      start: this._datePipe.transform(new Date(start), 'dd/MM/yyyy'),
      end: this._datePipe.transform(new Date(end), 'dd/MM/yyyy'),
      receptions: receptions
    });

    const date = this._datePipe.transform(new Date(), 'dd/MM/yyyy');

    return {
      content: data,
      styles: this.registreStyle(),
      pageOrientation: 'landscape',
      pageMargins: [15, 15, 15, 60],
      footer: {
        columns: [
          {
            width: '100%',
            text: this.translateService.instant('app.global.messages.documentGenererParBgw', {date: date}),
            font: 'Roboto',
            alignment: 'left',
            margin: [20, 20, 0, 20], fontSize: 8
          }
        ],
        columnGap: 500
      }

    };
  }

  public summarize(recep: any[], start: string, end: string, site: string = 'Tous les sites') {
    const receptions = recep.map(res => {
      return [
        { text: res.site.nom, style: 'detail', alignment: 'center' },
        { text: res.dateReception, style: 'detail', alignment: 'left' },
        { text: res.numReception || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.nom || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.transporteur.nom || '', style: 'detail', alignment: 'left' },
        { text: Intl.NumberFormat().format(res.poidsNet) + ' ', style: 'detail', alignment: 'right' }
      ];
    });
    const receptionClient = recep.filter(res => res.statutFacturationClient == 'En attente').map(res => {
      return [
        { text: res.site.nom, style: 'detail', alignment: 'center' },
        { text: res.dateReception, style: 'detail', alignment: 'left' },
        { text: res.numReception || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.nom || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.transporteur.nom || '', style: 'detail', alignment: 'left' },
        { text: Intl.NumberFormat().format(res.poidsNet) + ' ', style: 'detail', alignment: 'right' }
      ];
    });
    const receptionFournisseur = recep.filter(res => res.statutFacturationFournisseur == 'En attente').map(res => {
      return [
        { text: res.site.nom, style: 'detail', alignment: 'center' },
        { text: res.dateReception, style: 'detail', alignment: 'left' },
        { text: res.numReception || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.nom || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.transporteur.nom || '', style: 'detail', alignment: 'left' },
        { text: Intl.NumberFormat().format(res.poidsNet) + ' ', style: 'detail', alignment: 'right' }
      ];
    });
    const receptionRefusee = recep.filter(res => res.statutReception && res.statutReception.includes('Refusée')).map(res => {
      return [
        { text: res.site.nom, style: 'detail', alignment: 'center' },
        { text: res.dateReception, style: 'detail', alignment: 'left' },
        { text: res.numReception || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.nom || '', style: 'detail', alignment: 'left' },
        { text: res.gisement.transporteur.nom || '', style: 'detail', alignment: 'left' },
        { text: Intl.NumberFormat().format(res.poidsNet) + ' ', style: 'detail', alignment: 'right' }
      ];
    });
    const byGisements = _.groupBy(recep, 'gisement._id');
    let gisements = [];
    _.forEach(byGisements, (value) => {
      value[0].gisement.site = value[0].site;
      value[0].gisement.quantite = value.map(recep => recep.poidsNet).reduce((a, b) => a + b, 0);
      value[0].gisement.quantite = Number.parseFloat(value[0].gisement.quantite.toFixed(2));
      value[0].gisement.taux = value[0].gisement.quantiteAppro ? (value[0].gisement.quantite / value[0].gisement.quantiteAppro) * 100 : 0;
      value[0].gisement.taux = Number.parseFloat(value[0].gisement.taux.toFixed()) + '%';
      gisements.push(value[0].gisement);
    });
    gisements = gisements.map(gis => {
      return [
        { text: gis.site.nom, style: 'detail', alignment: 'center' },
        { text: gis.nom, style: 'detail', alignment: 'left' },
        { text: gis.quantiteAppro || '', style: 'detail', alignment: 'right' },
        { text: gis.quantite || '', style: 'detail', alignment: 'right' },
        { text: gis.taux || '', style: 'detail', alignment: 'right' }
      ];
    });

    receptions.length ? receptions.unshift([
      { text: this.translateService.instant('app.global.headers.site'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.dateReception'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.numReception'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.gisement'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.transporteur'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.quantiteLivree%'), style: 'tableHeader', alignment: 'right' }
    ]) : receptions.push([{ text: '-', style: 'detail', alignment: 'center' }]);

    receptionClient.length ? receptionClient.unshift([
      { text: this.translateService.instant('app.global.headers.site'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.dateReception'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.numReception'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.gisement'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.transporteur'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.quantiteLivree%'), style: 'tableHeader', alignment: 'right' }
    ]) : receptionClient.push([{ text: '-', style: 'detail', alignment: 'center' }]);

    receptionFournisseur.length ? receptionFournisseur.unshift([
      { text: this.translateService.instant('app.global.headers.site'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.dateReception'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.numReception'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.gisement'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.transporteur'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.quantiteLivree%'), style: 'tableHeader', alignment: 'right' }
    ]) : receptionFournisseur.push([{ text: '-', style: 'detail', alignment: 'center' }]);

    receptionRefusee.length ? receptionRefusee.unshift([
      { text: this.translateService.instant('app.global.headers.site'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.dateReception'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.numReception'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.gisement'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.transporteur'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.quantiteLivree%'), style: 'tableHeader', alignment: 'right' }
    ]) : receptionRefusee.push([{ text: '-', style: 'detail', alignment: 'center' }]);

    gisements.length ? gisements.unshift([
      { text: this.translateService.instant('app.global.headers.site'), style: 'tableHeader', alignment: 'center' },
      { text: this.translateService.instant('app.global.headers.gisement'), style: 'tableHeader', alignment: 'left' },
      { text: this.translateService.instant('app.global.headers.quantitePlanAppro%'), style: 'tableHeader', alignment: 'right' },
      { text: this.translateService.instant('app.global.headers.quantiteRecepPeriode%'), style: 'tableHeader', alignment: 'right' },
      { text: this.translateService.instant('app.global.headers.realisePrevue%'), style: 'tableHeader', alignment: 'right' }
    ]) : gisements.push([{ text: '-', style: 'detail', alignment: 'center' }]);

    const data = this.summaryTemplate({
      site: site,
      period: moment(end).diff(moment(start), 'days'),
      exportTime: this._datePipe.transform(new Date(), 'dd/MM/yyyy'),
      start: this._datePipe.transform(new Date(start), 'dd/MM/yyyy'),
      end: this._datePipe.transform(new Date(end), 'dd/MM/yyyy'),
      receptionClient: receptionClient,
      receptionFournisseur: receptionFournisseur,
      receptionRefusee: receptionRefusee,
      receptions: receptions,
      gisements: gisements,
      nbreReceptionClient: receptionClient.length - 1,
      nbreReceptionFournisseur: receptionFournisseur.length - 1,
      nbreReceptionRefusee: receptionRefusee.length - 1,
      nbreReception: receptions.length - 1,
      tonnage: Number.parseFloat(_.sumBy(recep, 'poidsNet').toFixed(2))
    });

    const date = this._datePipe.transform(new Date(), 'dd/MM/yyyy');
    return {
      content: data,
      styles: this.summaryStyle(),
      pageOrientation: 'landscape',
      pageMargins: [40, 40, 40, 60],
      footer: {
        columns: [
          {
            width: '100%',
            text: this.translateService.instant('app.global.messages.documentGenererParBgw', {date: date}),
            font: 'Roboto',
            alignment: 'left',
            margin: [20, 20, 0, 20], fontSize: 8
          }
        ],
        columnGap: 500
      }

    };
  }

  private toReception(recep: Object): Reception {
    const reception: Reception = new Reception();
    Object.keys(recep)
      .forEach(key => reception[key] = recep[key]);

    reception.gisement.cpc = reception.gisement.cpc.map(cpc => {
      cpc.nom = this.upgradeGisementCPC(cpc.nom);
      return cpc;
    });
    return reception;
  }

  private registreStyle() {
    return {
      header: {
        alignment: 'center',
        margin: [0, 25, 0, 10],
        bold: true,
        fontSize: 12,
        font: 'Roboto'
      },
      tableHeader: {
        fillColor: '#cccccc',
        bold: true,
        fontSize: 7
      },
      caption: {
        bold: true,
        fontSize: 8
      },
      detail: {
        fontSize: 7,
        font: 'Roboto'
      },
      table: {
        margin: [0, 15, 0, 10],
      }
    };
  }

  private summaryStyle() {
    return {
      header: {
        alignment: 'center',
        margin: [0, 25, 0, 10],
        bold: true,
        fontSize: 14,
        font: 'Roboto'
      },
      tableHeader: {
        fillColor: '#cccccc',
        bold: true,
        fontSize: 8
      },
      caption: {
        bold: true,
        fontSize: 8
      },
      detail: {
        fontSize: 9,
        font: 'Roboto'
      },
      table: {
        margin: [0, 15, 0, 10],
      }
    };
  }

  private summaryTemplate(data: any) {
    return [
      {
        columns: [
          { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.synthese-template.titre'), style: 'header', alignment: 'center', margin: [-116, 19, 0, 10] }
        ]
      },
      {
        columns: [
          {
            layout: {
              hLineWidth: function (i, node) { return 0.5; },
              vLineWidth: function (i, node) { return 0.5; }
            },
            table: {
              widths: ['60%', '40%'],
              headerRows: 1,
              body: [
                [
                  { text: this.translateService.instant('app.global.headers.site'), style: 'caption' },
                  { text: data.site || '', style: 'detail', alignment: 'center' }
                ],
                [
                  { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.synthese-template.dateCreation'), style: 'caption' },
                  { text: data.exportTime || '', style: 'detail', alignment: 'center' }
                ],
                [
                  { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.synthese-template.dateDebut'), style: 'caption' },
                  { text: data.start || '', style: 'detail', alignment: 'center' }
                ],
                [
                  { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.synthese-template.dateFin'), style: 'caption' },
                  { text: data.end || '', style: 'detail', alignment: 'center' }
                ],
                [
                  { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.synthese-template.dureePeriode'), style: 'caption' },
                  { text: Intl.NumberFormat().format(data.period) + ' ' + this.translateService.instant('app.global.termes.jours').toLowerCase() || '', style: 'detail', alignment: 'center' }
                ]
              ]
            }
          },
          {
            layout: {
              hLineWidth: function (i, node) { return 0.5; },
              vLineWidth: function (i, node) { return 0.5; }
            },
            table: {
              widths: ['70%', '30%'],
              headerRows: 1,
              body: [
                [
                  { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.synthese-template.nbRecepAttRapprochementClient'), style: 'caption' },
                  { text: data.nbreReceptionClient || ' 0', style: 'detail', alignment: 'right' }
                ],
                [
                  { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.synthese-template.nbRecepAttRapprochementFournisseur'), style: 'caption' },
                  { text: data.nbreReceptionFournisseur || ' 0', style: 'detail', alignment: 'right' }
                ],
                [
                  { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.synthese-template.nbRecepAttRefusees'), style: 'caption' },
                  { text: data.nbreReceptionRefusee || ' 0', style: 'detail', alignment: 'right' }
                ],
                [
                  { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.synthese-template.nbTotalRecep'), style: 'caption' },
                  { text: data.nbreReception || ' 0', style: 'detail', alignment: 'right' }
                ],
                [
                  { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.synthese-template.tonnageTotal'), style: 'caption' },
                  { text: data.tonnage || ' 0', style: 'detail', alignment: 'right' }
                ]
              ]
            }
          }
        ],
        columnGap: 290
      },
      {
        text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.synthese-template.recapitulatifParGisement'), alignment: 'left', style: 'table', bold: true
      },
      {
        layout: {
          hLineWidth: function (i, node) { return 0.5; },
          vLineWidth: function (i, node) { return 0.5; }
        },
        table: {
          widths: ['10%', '50%', '10%', '15%', '15%'],
          headerRows: 1,
          body: data.gisements
        }
      },
      {
        text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.synthese-template.receptionsRefusees'), alignment: 'left', style: 'table', bold: true
      },
      {
        layout: {
          hLineWidth: function (i, node) { return 0.5; },
          vLineWidth: function (i, node) { return 0.5; }
        },
        table: {
          widths: ['10%', '10%', '15%', '35%', '25%', '5%'],
          headerRows: 1,
          body: data.receptionRefusee
        }
      },
      {
        text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.synthese-template.detailReceptions'), alignment: 'left', style: 'table', bold: true
      },
      {
        layout: {
          hLineWidth: function (i, node) { return 0.5; },
          vLineWidth: function (i, node) { return 0.5; }
        },
        table: {
          widths: ['10%', '10%', '15%', '35%', '25%', '5%'],
          headerRows: 1,
          body: data.receptions
        }
      }
    ];
  }

  private registreTemplate(data: any) {
    return [
      {
        columns: [
          { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.registre-intrants-template.titre'), style: 'header', alignment: 'center'}
        ]
      },
      {
        columns: [
          {
            width: '30%',
            layout: {
              hLineWidth: function (i, node) { return 0.5; },
              vLineWidth: function (i, node) { return 0.5; }
            },
            table: {
              widths: ['40%', '60%'],
              headerRows: 1,
              body: [
                [
                  { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.registre-intrants-template.porteurProjet'), style: 'caption' },
                  { text: data.site || '', style: 'detail', alignment: 'center' }
                ],
                [
                  { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.registre-intrants-template.dateCreation'), style: 'caption' },
                  { text: data.exportTime || '', style: 'detail', alignment: 'center' }
                ],
                [
                  { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.registre-intrants-template.dateDebut'), style: 'caption' },
                  { text: data.start || '', style: 'detail', alignment: 'center' }
                ],
                [
                  { text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.registre-intrants-template.dateFin'), style: 'caption' },
                  { text: data.end || '', style: 'detail', alignment: 'center' }
                ]
              ]
            }
          }
        ]
      },
      {
        text: this.translateService.instant('app.modules.tracabilite.modules.appro.components.reception.registre-intrants-template.detailReceptions'), alignment: 'left', style: 'table', bold: true
      },
      {
        layout: {
          hLineWidth: function (i, node) { return 0.5; },
          vLineWidth: function (i, node) { return 0.5; }
        },
        table: {
          widths: ['9%', '6%', '6%', '4%', '9%', '8%', '6%', '9%', '8%', '6%', '6%', '6%', '6%', '4%', '3%', '4%'],
          headerRows: 1,
          body: data.receptions
        }
      }
    ];
  }

  private getAdresse(adresse: any) {
    let help = '';
    if (adresse && adresse.adresse) { help += `${adresse.adresse},`; }
    if (adresse && adresse.commune) { return `${help} ${adresse.codePostal}, ${adresse.commune}`; }
    return adresse.codePostal;
  }

  private upgradeGisementCPC(name: string): string {
    const cpcList = [
      { 'old': 'MS/MB', 'new': 'MS/MB' },
      { 'old': 'MO/MB', 'new': 'MO/MB' },
      { 'old': 'MO/MS', 'new': 'MO/MS' },
      { 'old': 'MM/MB', 'new': 'MM/MS' },
      { 'old': 'pH', 'new': 'pH' },
      { 'old': 'Conductivité', 'new': 'Conductivite' },
      { 'old': 'Red/Ox', 'new': 'Red/Ox' },
      { 'old': 'PM/MB', 'new': 'PM/MB stand' },
      { 'old': 'PB/MB', 'new': 'PB/MB stand' },
      { 'old': 'PM/MO', 'new': 'PM/MO stand' },
      { 'old': 'PB/MO', 'new': 'PB/MO stand' },
      { 'old': 'CH4', 'new': '%CH4 stand' },
      { 'old': 'N', 'new': 'Ntot' },
      { 'old': 'P205', 'new': 'P2O5' },
      { 'old': 'K2O', 'new': 'K2O' },
      { 'old': 'C org.', 'new': 'C org.' },
      { 'old': 'C/N', 'new': 'C/N' },
      { 'old': 'N-NH4', 'new': 'N-NH4' },
      { 'old': 'N-NH3', 'new': 'N-NH3' },
      { 'old': 'N-NO3', 'new': 'N-NO3' },
      { 'old': 'CaO', 'new': 'CaO' },
      { 'old': 'MgO', 'new': 'MgO' },
      { 'old': 'Na2O', 'new': 'Na2O' },
      { 'old': 'SO3', 'new': 'SO3' },
      { 'old': 'Chlorures', 'new': 'Chlorures' },
      { 'old': 'Cellulose brute', 'new': 'Cellulose brute' },
      { 'old': 'Cendre total', 'new': 'Cendre total' },
      { 'old': 'Digestibilité de la MO', 'new': 'Digestibilite de la MO' },
      { 'old': 'Extractif non azoté réel', 'new': 'Extractif non azoté réel' },
      { 'old': 'Matière grasse brute', 'new': 'Matiere grasse brute' },
      { 'old': 'Matière organique indigérée', 'new': 'Matiere organique indigeree' },
      { 'old': 'Protéine brute', 'new': 'Proteine brute' },
      { 'old': 'Protéine brute réelle', 'new': 'Proteine brute reelle' },
      { 'old': 'Br', 'new': 'B' },
      { 'old': 'Fe', 'new': 'Fe' },
      { 'old': 'Mn', 'new': 'Mn' },
      { 'old': 'Mo', 'new': 'Mo' },
      { 'old': 'Co', 'new': 'Co' },
      { 'old': 'Cd', 'new': 'Cd' },
      { 'old': 'Cr', 'new': 'Cr' },
      { 'old': 'Cu', 'new': 'Cu' },
      { 'old': 'Hg', 'new': 'Hg' },
      { 'old': 'Ni', 'new': 'Ni' },
      { 'old': 'Pb', 'new': 'Pb' },
      { 'old': 'Zn', 'new': 'Zn' },
      { 'old': 'P', 'new': 'P' },
      { 'old': 'K', 'new': 'K' },
      { 'old': 'Ca', 'new': 'Ca' },
      { 'old': 'Mg', 'new': 'Mg' },
      { 'old': 'Na', 'new': 'Na' },
      { 'old': 'S', 'new': 'S' },
      { 'old': 'Se', 'new': 'Se' },
      { 'old': 'As', 'new': 'As' },
      { 'old': 'Cr+Cu+Ni+Zn', 'new': 'Cr+Cu+Ni+Zn' },
      { 'old': 'C2', 'new': 'C2' },
      { 'old': 'C3', 'new': 'C3' },
      { 'old': 'iC4', 'new': 'iC4' },
      { 'old': 'C4', 'new': 'C4' },
      { 'old': 'iC5', 'new': 'iC5' },
      { 'old': 'C5', 'new': 'C5' },
      { 'old': 'AGV', 'new': 'AGV' },
      { 'old': 'TAC', 'new': 'TAC' },
      { 'old': 'FOS', 'new': 'FOS' },
      { 'old': 'FOSTAC', 'new': 'FOSTAC' },
      { 'old': 'Salmonelles', 'new': 'Salmonelles-MS' },
      { 'old': 'Helminthes', 'new': 'Helminthes-MS' },
      { 'old': 'Germes', 'new': 'Germes-MS' },
      { 'old': 'Coliformes', 'new': 'Coliformes-MS' },
      { 'old': 'E.coli', 'new': 'E.coli-MS' },
      { 'old': 'Spores', 'new': 'Spores-MS' },
      { 'old': 'C16H10', 'new': 'C16H10' },
      { 'old': 'C20H12(b)', 'new': 'C20H12(b)' },
      { 'old': 'C20H12(a)', 'new': 'C20H12(a)' },
      { 'old': 'PCB', 'new': 'PCB tot' },
      { 'old': 'HAP', 'new': 'HAP16' }
    ];

    const cpc = cpcList.find(cpc => cpc.old == name);
    if (cpc) { return cpc.new; }
    return name;

  }

}
