import { Injectable } from '@angular/core';
import { Variable } from 'rondes/models/dto/variable';
import { Site } from 'global/models/dto/site';
import { DatabaseService } from 'global/services/database/database.service';
import { CommonDatabaseQueryService } from 'global/services/common-database-query/common-database-query.service';
import * as uuid from 'uuid/v4';
import * as _ from 'lodash';
import { defer } from 'rxjs';
import {Parcelle} from "epandages/models/dto/parcelle";

@Injectable({
  providedIn: 'root'
})
export class VariableService extends CommonDatabaseQueryService{

	headers = [
    {nom: "nom", label: "Nom"},
    {nom: "equipement", label: "Équipement"},
    {nom: "unite.label", label: "Unité"},
    {nom: "frequence", label: "Fréquence"},
    {nom: "type", label: "Type"},
  ]

  constructor(_databaseService : DatabaseService) {
    super(_databaseService,{
      all: 'Variable/all',
      fetch_all: 'Variable/fetch_all',
      joins : 'Variable/joins',
      by_site : 'Variable/by_site',
      by_code : 'Variable/by_code'
    });
  }

  public create(variable : Variable){
    let user = JSON.parse(sessionStorage.getItem('loggedUser'));
    variable.createdBy = user.email;
    variable.createdOn = new Date().getTime();
    variable.modifiedBy = user.email;
    variable.modifiedOn = new Date().getTime();
    variable._id = 'variable_' + uuid();
    return this._dbService.db.put(variable.toDao());
  }

  public update(variable : Variable){
    let user = JSON.parse(sessionStorage.getItem('loggedUser'));
    return this._dbService.db.get(variable._id).then(doc=>{
      variable.modifiedBy = user.email;
      variable.modifiedOn = new Date().getTime();
      variable.exported = false;
      let help = variable.toDao();
      help._rev = doc._rev;
      return this._dbService.db.put(help);
    });
  }

  public joins(docId : string = undefined, skip: number = 0, limit: number = 10000,desc: boolean = false){
    return super.joins(docId, skip,limit,desc)
      .then( res => {
          let variables = new Array<Variable>();
          let variable = new Variable();
          for(let i = 0; i< res.rows.length; i++){
            if(res.rows[i].key[1] == 0){
              variable = res.rows[i].doc as Variable;
              variables.push(variable);
            }
            else if(res.rows[i].key[1] == 1) variable.site = res.rows[i].doc as Site;
          }
          return variables;
      }).catch(error => defer(()=>Promise.reject(error)))
  }

  public checkIfExists(variable : Variable){
    return this._dbService.db.query(
        'Variable/by_code',
        {
          include_docs: true,
          key : variable.code
        }
    ).then(res => {
      let sameObject = false;
      if(res.rows.length == 0) return false;
      else if(variable._id){
        res.rows.forEach(row => {
          if(row.id == variable._id) {
            sameObject = row.id == variable._id;
          }
        })
        return !sameObject;
      }
      return true;
    })
  }

  public getIncrement(){
    let inc = this._dbService.increment("variableCounter");
    let variable = new Variable();
    variable.code = 'V'+inc;
    return this.checkIfExists(variable).then(res => {
      if(!res) return inc;
      else return this.getIncrement();
    }).catch(error => defer(()=>Promise.reject(error)))
  }

  /**
  * Joins by site
  * @param site - site
  * @param skip - number of rows to skip
  * @param limit - max number of rows to return
  * @param desc - descending sort param
  */
  public bySite(site: string = undefined, skip: number = 0, limit: number = 10000, desc: boolean = false): any {
    return super.bySite(site, skip, limit, desc)
      .then(res => {
        let variable: Variable;
        let site: Site;
        let variables = new Array<Variable>();
        let sites = new Array<Site>();
        for (let i = 0; i < res.rows.length; i++) {
          if (res.rows[i].key[1] == 0 && res.rows[i].doc) {
            delete res.rows[i].doc.siteId;
            variable = res.rows[i].doc as Variable;
            variable.frequence = this.getFrequence(variable);
            variables.push(variable);
          }
          else if (res.rows[i].key[1] == 1 && res.rows[i].doc) {
            site = res.rows[i].doc as Site;
            sites.push(site);
          }
        }
        sites.forEach((site, index) => {
          variables[index].site = site;
        })
        variables = variables.filter(variable => variable.nom != undefined);
        return _.sortBy(variables,(t) => t.nom.toLocaleLowerCase());
      })
      .catch(error => defer(() => Promise.reject(error)))
  }

  public delete(variable: Variable): any{
    return this._dbService.db.get(variable._id).then(doc => {
      variable._rev = doc._rev;
      return this._dbService.db.remove(variable);
    });
  }

  public bulkDelete(variables: Variable[]){
    let promises = [];
    promises = variables.map(variable => this.delete(variable));
    return Promise.all(promises);
  }

  public getFrequenceValue(variable: any): number{
    let temp;
    switch(variable.frequence){
      case `Tous les jours`: temp = 1; break;
      case `Toutes les semaines`: temp = 7; break;
      case `Tous les mois`: temp = 30; break;
      default: temp = parseInt(variable.frequence.replace('Tous les ','').replace(' jours', '')); break;
    }
    return temp;
  }

  private getFrequence(variable: any): string{
    let temp = '';
    if(variable.frequence && isNaN(variable.frequence)) {
      temp = variable.frequence;
    }
    else {
      switch(variable.frequence){
        case 1: temp = `Tous les jours`; break;
        case 7: temp = `Toutes les semaines`; break;
        case 30: temp = `Tous les mois`; break;
        default: temp = `Tous les ${variable.frequence} jours`; break;
      }
    }
    return temp;
  }

}
