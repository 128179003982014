import { SiteDocumentDAO } from 'global/models/dao/site-document';
import { Gisement } from 'tracabilite/models/dto/gisement';
import { Tiers } from 'tracabilite/models/dto/tiers';
import { Reception } from './../dto/reception';

export class ReceptionDAO extends SiteDocumentDAO{
	public dateReception : any;
	public gisement : Gisement;
	public badge : string;
	public numReception : string;
	public numPesee : string;
	public dsdEntree : string;
	public dsdSortie : string;
	public poidsEntree : number;
	public poidsSortie : number;
	public poidsNet : number;
	public statutReception : string;
	public statutFacturation : string;
	public documentAEmettre : string;
	public commentaire : string;
	public tare: number;
	public plaque: string;
	public codeUtilisateur: string;
	public nbreColis: number;
	public notReportable: boolean;
	public dateIncorporation: any;
	public numDocument: string;
	public numBL: string;
	public numBenne: string;
	public chauffeur: string;
	public numLot: string;
	public parcelle: any;
	public certifieDurable: boolean;
	public tauxMatiereDurable: number;

	constructor(){
		super();
		this.dataType = 'reception';
	}

	toDto(): Reception{
		let reception: Reception = new Reception();
		Object.keys(this)
			.forEach(key => reception[key]= this[key]);
		return reception;
	}
}
